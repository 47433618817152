/* avoid click on plus icon */
.rovo-modal-open_btn {
  pointer-events: none;
}

.rovo-list-modal__list {
  position: relative;
  z-index: 0;
}

.rovo-list-modal {
  display: none;
  opacity: 1;
}

.rovo-list-modal__item {
  overscroll-behavior-y: contain;
}

.rovo-list-modal--clone {
  display: block;
}

@media only screen and (min-width: 768px) {
  .rovo-list-modal--overlay--clone {
    display: block;
    opacity: 1;
  }
}
