body {
  min-height: -webkit-fill-available;
}

@media (min-width: 992px) {
  /* hide scrollbar but allow scrolling */
  html {
    -ms-overflow-style: none; /* for Internet Explorer, Edge */
    scrollbar-width: none; /* for Firefox */
    overflow-y: scroll;
    height: -webkit-fill-available;
  }

  body {
    min-height: -webkit-fill-available;
  }

  body::-webkit-scrollbar {
    display: none; /* for Chrome, Safari, and Opera */
  }
}
