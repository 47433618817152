/* slider */
.swiper {
  width: 100%;
  height: auto;
}

/* slider sectin Investments */
.rovo-swiper {
  padding-top: 40px;
  margin-top: -55px;
  z-index: 0;
}

/* slider pagination index */
.swiper-pagination {
  z-index: 9;
}

/* slider pagination */
.swiper-pagination-bullet {
  width: 2rem;
  height: 0.1875rem;
  background: #c6c6c6;
  border-radius: 0;
  opacity: 0.5;
}

/* slider pagination */
.swiper-pagination-bullet-active {
  background: #000000;
  opacity: 1;
}

/* slider cards pagination */
#rovo-slider-cards + .swiper-pagination {
  top: 0px !important;
  height: 1.5rem;
  display: flex;
  align-items: flex-end;
  justify-content: flex-end;
}

#rovo-slider-selected-clients + .swiper-pagination {
  position: absolute;
  bottom: -40px;
}

@media only screen and (min-width: 768px) {
  /* slider pagination */
  .swiper-pagination {
    top: 0 !important;
    padding-right: 1.5625rem;
    height: 1.5rem;
    display: flex;
    align-items: flex-end;
    justify-content: flex-end;
  }

  /* responsible for make sliders touch screen on the right */
  .rovo-slider-cards {
    width: calc(100% + 1.5625rem);
  }
}

@media only screen and (min-width: 991px) {
  /* responsible for make sliders touch screen on the right */
  .rovo-slider-cards {
    width: calc(100% + 1.875rem);
  }

  .swiper-pagination {
    padding-right: 1.875rem;
  }
}
