.rovo-inquiry-form__select--error {
  color: #ff8181;
  border-color: #ff8181;
}

.rovo-inquiry-form__field--error {
  color: #ff8181;
  border-color: #ff8181;
}

.rovo-inquiry-form__field--error::placeholder {
  color: #ff8181;
}

.form-success > .rovo-inquiry-form__button {
  background-color: #000;
}
